import TYPES from '@/types';
import Vue from 'vue';

// Application
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type LinkedGoals = {
  name: string;
  icon: string;
  alt: string;
  investor_goal_id: string;
  updated_at: string;
}

export default class LinkStrategyModerateLinkedGoalsViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.link-sws-moderate.link-strategy-moderate-linked-goals';

  private readonly view: Vue;

  readonly BUCKET_URL = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  linked_moderate_goals: Array<LinkedGoals> = [];

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.view.$emit('setDynamicWidth', '35');
    await this.loadInvestmentProducts();
    await this.loadSearchByCustomerActiveGoal();
  }

  getIconPath = (goal: LinkedGoals) => {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    let icon = require(`@/assets/icons/custom-goal-icons/${goal.icon}`);
    if (goal.icon === 'icon-add.svg') {
      icon = `${this.BUCKET_URL}${goal.investor_goal_id}?${this.getCustomGoalUpdatedAtTimeStamp(goal.updated_at)}`;
    }
    return icon;
  }

  getCustomGoalUpdatedAtTimeStamp = (date: string) => (new Date(date).getTime());

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '24px');

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const investment_product = (investment_products.find(
        (item) => item.name === 'sowos_wealth',
      ));
      this.search_by_customer_dto.associated_product_id = investment_product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_investment_products_query'));
    }
  }

  loadSearchByCustomerActiveGoal = async () => {
    try {
      const active_goals = await this
        .get_search_by_customer_query.execute(this.search_by_customer_dto);

      let linked = false;

      active_goals.forEach((goal) => {
        linked = false;
        if (goal.investment_product_fund_types) {
          linked = verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          );
        }
        if (linked) {
          this.linked_moderate_goals.push({
            // eslint-disable-next-line max-len
            name: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
            // eslint-disable-next-line max-len
            alt: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
            icon: (goal.custom_investor_goal) ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
            investor_goal_id: goal.investor_goal_id,
            updated_at: goal.custom_investor_goal?.updated_at || '',
          });
        }
      });
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_search_by_customer_active_goal'));
      }
    }
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }
}
