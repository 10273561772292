









































































import {
  Component, Vue,
} from 'vue-property-decorator';
import LinkStrategyModerateLinkedGoalsViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-goal/link-strategy-moderate-linked-goals-view-model';

@Component({ name: 'LinkStrategyModerateLinkedGoals' })
export default class LinkStrategyModerateLinkedGoals extends Vue {
  link_strategy_moderate_wealth_model = Vue.observable(
    new LinkStrategyModerateLinkedGoalsViewModel(this),
  );

  mounted() {
    this.link_strategy_moderate_wealth_model.initialize();
  }
}
